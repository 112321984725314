/* eslint-disable jsx-a11y/alt-text */
import Navbar from '../Navbar';
import construction from '../../assets/ConstructionFilled.svg';
import '../../style/chat.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { useEffect, useRef, useState } from 'react';
import { fetchGetMessagesRequest } from '../../store/actions/get-messages.actions';
import { IGetMessagesResponse } from '../../features/models/IGetMessagesResponse';
import { extractDate, extractDateSimplified, extractTime } from '../../helpers/Helpers';
import chat from '../../assets/ChatFilledWhite.svg';
import arrow from '../../assets/ArrowForwardFilled.svg';
import { IPostMessageModel } from '../../features/models/IPostMessageModel';
import { postMessage } from '../../features/auth/Auth';
import $ from "jquery";
import axios from 'axios';
import { appBaseUrl } from '../../features/auth/RefreshToken';
import mixpanel from 'mixpanel-browser';
import warningImg from '../../assets/Group.svg';





const Chat = () => {

  const dispatch = useDispatch();

  const [text, setText] = useState<any>()
  const [messagePosted, setMessagePosted] = useState(false)
  
  const { loading, messages } =  useSelector(   
    (state: RootState) => state.messagesState,
    
  )

  useEffect(() => {    
    dispatch(fetchGetMessagesRequest())
    setMessagePosted(false)
    setText("")
  }, [messagePosted])

  function sendMessage(){
    if(text.length>0){postNewMessage()}
  }

  const postNewMessage =  async () => {
             await axios.post(appBaseUrl+"messages",{
              text: text
             })
             setMessagePosted(true)
             mixpanel.track('Chat/Send Message',{
              'messageLength': text.length
             })
    }

  const handleTextInput = (e:any) => setText(e.target.value);
  

  function checkMessageType(isOwnedByCurrentUser: boolean, msg: IGetMessagesResponse){
    if(isOwnedByCurrentUser===true){
      return <div>
      <div className='message-timestamp'>
        <h3>{extractDateSimplified(msg.createdAt)} {extractTime(msg.createdAt)}</h3>
      </div>
      <div className="message-my">
      <div className="message-username-left"><h4>{msg.userDisplayName}</h4></div>
      <p className="message-content">{msg.text}</p>
     </div>
     </div>
    }else{
      return <div>
      <div className='message-timestamp'>
      <h3>{extractDateSimplified(msg.createdAt)} {extractTime(msg.createdAt)}</h3>
      </div>
      <div className="message-their">
      <div className="message-username-left"><h4>{msg.userDisplayName}</h4></div>
      <p className="message-content">{msg.text}</p>
      </div>
      </div>
    }
  }


    let content;

    if (loading) {

      content =             
      <div >
      <div className="header-page">
      <h1>Chatt</h1>
      </div>
      <div className="loader-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      <Navbar></Navbar>
  </div>
    }else if(!loading && messages.length>0){
      content = (
        <div className='block-container' id="block-container">
        <div className="header-page-chat">
        <h1>Chatt</h1>
        <p>Intern konversation  för BO {messages[0].guardDistrict}</p>
        <div className='chat-banner'>
        <img className="warning-img" src={warningImg}></img>
        <h5>Denna chatt delas med dina kollegor som jobbar på samma bevakningsområde. Du får inte dela känslig information i chatten, så som brott, sjukdom eller personuppgifter (som kan knytas direkt eller indirekt till en viss person). </h5>
        </div>
        </div>
        <nav id="menu-chat" className="menu-chat"></nav>
        <div className='chatContainer' id="chatContainer">
          <div className='chatList' id='chatList'>
          {
          messages.map((msg: IGetMessagesResponse) => (
            <div key={msg.createdAt}>
            {checkMessageType(msg.isOwnedByCurrentUser, msg)}    
            </div>

          )
          )
        }
          <div ref={el => el?.scrollIntoView()}/>
          </div>
          <div id="list-end"></div>
          </div>
          <div>
          <div className='textInput-container'>
          <input type="text" id="message" name="fname" autoComplete='off' placeholder='Type a message...' maxLength={1000} onChange={handleTextInput}></input>
          <div className='send-btn-container'>
          <button className='send-message-btn' id="send-message-btn" type="submit" onClick={() =>sendMessage()}><img src={arrow}></img></button>

          </div>
          </div>

          </div>

        <Navbar></Navbar>
        </div>
    );
    }else if(!loading && messages.length<1){
          content = (
            <div className='block-container' id="block-container">
            <div className="header-page-chat">
              <h1>Chatt</h1>
              <div className='chat-banner'>
              <img className="warning-img" src={warningImg}></img>
                <h5>Denna chatt delas med dina kollegor som jobbar på samma bevakningsområde. Du får inte dela känslig information i chatten, så som brott, sjukdom eller personuppgifter (som kan knytas direkt eller indirekt till en viss person). </h5>
              </div>
              </div>
              <nav id="menu-chat" className="menu-chat">
                <div className="chat-container">
                <img className="construction-img" src={chat}></img>
                <p id="construction-text">Starta en konversation på det här bevakningsobjektet (BO)</p>
                </div>
              </nav>
          <div>
          <div className='textInput-container'>
          <input type="text" autoComplete='off' id="message" name="fname" maxLength={1000} placeholder='Type a message...' onChange={handleTextInput}></input>
          <div className='send-btn-container'>
          <button className='send-message-btn' type="submit" onClick={sendMessage}><img src={arrow}></img></button>
          </div>
          </div>
          </div>

              <Navbar></Navbar>
        
          </div>);
    }else{
    content = (
              <div >
              <div className="header-page">
              <h1>Chatt</h1>
              </div>
              <nav id="menu-chat" className="menu-chat">
                <div className="chat-container">
                <img className="construction-img" src={construction}></img>
                <p id="construction-text">Vi håller på att utveckla en chattfunktion så att ni kan kommunicera internt på samma bevakningsobjekt (BO).</p>
                </div>
              </nav>
              <Navbar></Navbar>
        
          </div>);    
        }




    // content = (
    //           <div >
    //           <div className="header-page">
    //           <h1>Chatt</h1>
    //           </div>
    //           <nav id="menu-chat" className="menu-chat">
    //             <div className="chat-container">
    //             <img className="construction-img" src={construction}></img>
    //             <p id="construction-text">Vi håller på att utveckla en chattfunktion så att ni kan kommunicera internt på samma bevakningsobjekt (BO).</p>
    //             </div>
    //           </nav>
    //           <Navbar></Navbar>
        
    //       </div>);

    return content
}
export default Chat;