import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IFilterOptions {
    pageSize: number,
    skipSize: number,
    skipBeatsWithoutNotesOrImages: boolean,
    reportType: string[],
    locationIds:string[]
  }

  const initialState: IFilterOptions = {
    pageSize: 20,
    skipSize: 0,
    skipBeatsWithoutNotesOrImages: true,
    reportType: [],
    locationIds:[]
    
  }

  const filterSlice = createSlice({
    name: 'filterOptions',
    initialState,
    reducers:{
        setPageSize(state, action: PayloadAction<number>){
            state.pageSize = action.payload
        },
        setSkipSize(state, action: PayloadAction<number>){
            state.skipSize = action.payload
        },
        setSkipBeatsWithoutNotesOrImages(state, action: PayloadAction<boolean>){
            state.skipBeatsWithoutNotesOrImages = action.payload
        },
        setReportType(state, action: PayloadAction<string[]>){
            state.reportType = [...action.payload]
        },
        setLocationIds(state, action: PayloadAction<string[]>){
            state.locationIds = [...action.payload]
        },
        clearFilter(state){
            state.skipSize = 0;
            state.skipBeatsWithoutNotesOrImages = true;
            state.reportType = [];
            state.locationIds = [];

        }
    }
  })

  export const { setPageSize, setSkipSize, setSkipBeatsWithoutNotesOrImages, setReportType, setLocationIds, clearFilter } = filterSlice.actions
export default filterSlice.reducer