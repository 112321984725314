/* eslint-disable jsx-a11y/alt-text */
import React,{useState} from "react";
import Navbar from '../components/Navbar';
import chat from '../assets/Chat.svg';
import securitasLogo from '../assets/Securitas Logo - With White Text.svg';
import reports from '../assets/Reports, Rapporter.svg';
import { NavLink } from 'react-router-dom';
import '../style/menu.css';
import {Navigate} from "react-router-dom";
import logoutIcon from '../assets/LogoutFilled.svg';
import { useDispatch } from 'react-redux'
import { logout } from "../features/auth/Auth";
import IUserModel from "../features/models/IUserModel";
import mixpanel from "mixpanel-browser";


export default function Menu(){


    const user = localStorage.getItem("user") || "";
    const token = JSON.parse(user) as IUserModel

    const logoutClicked = async () => {
        logout(token.refreshToken)
        localStorage.clear();
        mixpanel.track('Logout Request')
    }

    function setAnalytics(selectedPage:string){
        mixpanel.track(selectedPage + ' Opened')

    }
    return <div>
        <div className="header-page">
        <h1>Meny</h1>
        </div>
        <nav className="menu">
        <ul>
            <li>
            <img className="menu-img" src={reports}></img> 
            <NavLink to="/reports" className="item-menu" onClick={()=>setAnalytics("Reports")}>Rapporter<p>Fördjupa dig i tidigare rapporter</p></NavLink>
            </li>
            <div className='menu-divider'></div>
            <li>
            <img className="menu-img" src={chat}></img>
            <NavLink to="/chat" className="item-menu" onClick={()=>setAnalytics("Chat")}>Chatt<p>För en konversation med dina kollegor på ditt BO</p></NavLink>
            </li>
            <div className='menu-divider'></div>
        </ul>
    </nav>
    <div className="logout-container">
    <img className="img-logout" src={logoutIcon}></img>
    <NavLink to="/" className="button-logout" onClick={logoutClicked}><p>Logga ut</p></NavLink>
    </div>
    <div className="copyright">
    <img className="img-securitas" src={securitasLogo}></img>
        <p>Copyright © 2022 Securitas Intelligent Services AB. Guard Interaction Platform (GIP)</p>
    </div>
    <Navbar></Navbar>
    </div>
    
}

