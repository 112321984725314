import { FetchReportsSearchAddItemSuccessPayload, FetchReportsSearchFailure, FetchReportsSearchFailurePayload, FetchReportsSearchRequest, FetchReportsSearchRequestPayload, FetchReportsSearchSuccessPayload, FetchReprotsSearchAddItemSuccess, FetchReprotsSearchSuccess } from "../types/search-reports.types"
import { clearTypes, searchReportsReqestTypes } from "./action-types"

export const fetchReportsRequest = (payload: FetchReportsSearchRequestPayload): FetchReportsSearchRequest => ({

    type: searchReportsReqestTypes.SEARCH_REPORTS_REQUEST,

    payload

})

export const fetchReportsSuccess = (payload: FetchReportsSearchSuccessPayload): FetchReprotsSearchSuccess => ({

    type: searchReportsReqestTypes.SEARCH_REPORTS_SUCCESS,


    payload

})

export const fetchReportsFailure = (payload: FetchReportsSearchFailurePayload): FetchReportsSearchFailure => ({

    type: searchReportsReqestTypes.SEARCH_REPORTS_FAILURE,

    payload

})

export const fetchReportsAddItemSuccess = (payload: FetchReportsSearchAddItemSuccessPayload): FetchReprotsSearchAddItemSuccess => ({

    type: searchReportsReqestTypes.SEARCH_REPORTS_ADD_ELEMENT,

    payload
})

export const resetEventsInStore = () => ({

    type: clearTypes.SEARCH_REPORTS_CLEAR_LIST
})