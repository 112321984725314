//ENGLISH


// export function extractDate(eventDate: string | number | Date){
//     console.log(eventDate)
//   const dateToDate = new Date(eventDate);
//     // dateToDate.toDateString();
//       console.log(dateToDate)
//     var formatOptions:any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
//         console.log(dateToDate.toLocaleDateString('en-US', formatOptions))

//     return dateToDate.toLocaleDateString('en-US', formatOptions);

// }


//SVENSKA

export function extractDate(eventDate: string){
  var date = new Date(eventDate);
  var formatOptions:any = {weekday: 'long',  year: 'numeric', month: 'long', day: 'numeric' };
  var convertedDate = date.toLocaleDateString('sv-SE', formatOptions);
  return convertedDate[0].toUpperCase() + convertedDate.slice(1);
}

export function extractTime(eventDate: string){
    var date = new Date(eventDate);
    var minutes = date.getMinutes().toLocaleString().length === 1 ? "0" + date.getMinutes() : date.getMinutes();
    var timeSimplified = date.getHours() + ":" + minutes;
    return timeSimplified;

  }

  export function extractDateSimplified(eventDate: string){
    var date = new Date(eventDate);
    var formatOptions:any = {month: 'short', day: 'numeric' };
    var convertedDate = date.toLocaleDateString('sv-SE', formatOptions);
    return convertedDate;
  }
