import { FetchGetMessagesRequest, FetchGetMessagesSuccess, FetchGetMessagesFailure, FetchGetMessgesSuccessPayload, FetchGetMessagesFailurePayload } from "../types/get-messages.types"
import { getMessagesReqestType } from "./action-types"

export const fetchGetMessagesRequest = () => ({

    type: getMessagesReqestType.GET_MESSAGES_REQUEST,


})

export const fetchGetMessagesSuccess = (payload: FetchGetMessgesSuccessPayload): FetchGetMessagesSuccess => ({

    type: getMessagesReqestType.GET_MESSAGES_SUCCESS,

    payload

})

export const fetchGetMessagesFailure = (payload: FetchGetMessagesFailurePayload): FetchGetMessagesFailure => ({

    type: getMessagesReqestType.GET_MESSAGES_FAILURE,

    payload

})



