import { FetchGetSitesFailure, FetchGetSitesFailurePayload, FetchGetSitesSuccess, FetchGetSitesSuccessPayload } from "../types/get-sites.types"
import { FetchReportsSearchAddItemSuccessPayload, FetchReportsSearchFailure, FetchReportsSearchFailurePayload, FetchReportsSearchRequest, FetchReportsSearchRequestPayload, FetchReportsSearchSuccessPayload, FetchReprotsSearchAddItemSuccess, FetchReprotsSearchSuccess } from "../types/search-reports.types"
import { clearTypes, getSitesReqestTypes } from "./action-types"

export const fetchGetSitesRequest = () => ({

    type: getSitesReqestTypes.GET_SITES_REQUEST,


})

export const fetchGetSitesSuccess = (payload: FetchGetSitesSuccessPayload): FetchGetSitesSuccess => ({

    type: getSitesReqestTypes.GET_SITES_SUCCESS,

    payload

})

export const fetchGetSitesFailure = (payload: FetchGetSitesFailurePayload): FetchGetSitesFailure => ({

    type: getSitesReqestTypes.GET_SITES_FAILURE,

    payload

})

export const resetStore = () => ({

    type: clearTypes.SEARCH_REPORTS_CLEAR_LIST
})

