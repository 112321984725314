import { AppState } from "./appState";
import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore, configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { rootSaga } from "./rootSaga";
import IReportsModel from "./features/models/IReportsModel";
import IReportsState from "./features/models/I-reports.state";
import IEventsModel from "./features/models/IEventsModel";
import { compose } from "redux";
import { curryGetDefaultMiddleware } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import thunkMiddleware from "redux-thunk";
import IAllSitesModel from "./features/models/IAllSitesModel";
import { IGetMessagesResponse } from "./features/models/IGetMessagesResponse";
import { IMessagesState } from "./features/models/IMessagesState";

const initialState: AppState = {
  reportsState: {
    reportsModel: {
      events: [],
      reportsTotalCount: 0,
    } as IReportsModel,
    loading: false,
    error: null,
  },

  sitesState: {
    sitesModel: [
      {
        id: "",
        label: "",
        properties: {},
        propertiesFlattened: false,
        outEdges: [],
      },
    ] as IAllSitesModel[],
    loading: false,
    error: null,
  },

  filterState: {
    pageSize: 20,
    skipSize: 0,
    skipBeatsWithoutNotesOrImages: true,
    reportType: [],
    locationIds: [],
  },
  messagesState: {
    messages: [],
    loading: false,
    error: null,
  } as IMessagesState,

  filterFormState: {
    pageSizeForm: 20,
    skipSizeForm: 0,
    skipBeatsWithoutNotesOrImagesForm: true,
    reportTypeForm: [],
    locationIdsForm: [],
  },
};

const sagaMiddleware = createSagaMiddleware();

// @ts-ignore: REDUX DEV TOOLS

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware, thunkMiddleware))
);

sagaMiddleware.run(rootSaga);

export default store;
