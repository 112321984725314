import instance from "../auth/RefreshToken"
import { IPostMessageModel } from "../models/IPostMessageModel";

// export const login = async (username: string, password: string) => {
//   const response = await axios
//     .post(API_URL + "authenticate", {
//       username,
//       password,
//     });
//   if (response.data.jwtToken) {
//     localStorage.setItem("user", JSON.stringify(response.data));
//   }
//   return response.data;
// };

// export const logout = () => {
//   localStorage.removeItem("user");
// };

export const login = (username: string, password: string) => {
  return instance.post("users/authenticate", {
      username,
      password,
    });
};

export const logout = (token:any) => {
  return instance.post("users/revoke-token", token)
};

export const postMessage = (text:IPostMessageModel) => {
  return instance.post("messages", {Text:text})
}

export const getImages = (image:string) => {
  return instance.get("reports/imageAttached/" + image)
}

