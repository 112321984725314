import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import axios, { AxiosRequestConfig } from 'axios'
import IUserModel from './features/models/IUserModel';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { HashRouter } from "react-router-dom";
import mixpanel from 'mixpanel-browser';


export const mixPanelToken = "d37aac88c1f003f71b8cbe3a0f7dc42d";

mixpanel.init(mixPanelToken, {debug: true}); 




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

axios.interceptors.request.use(function (config) {

  const user = localStorage.getItem("user") || "";

  if(config.headers&&user){

    const token = JSON.parse(user) as IUserModel
    config.headers.Authorization ='Bearer ' + token.jwtToken;
  }

  return config;

});

root.render(
    <Provider store={store}>
      <HashRouter>
        <App />
      </HashRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
