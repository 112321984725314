/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { Navigate, NavLink } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import React,{useEffect, useReducer, useState} from "react";
import Navbar from '../Navbar';
import bell from '../../assets/RedBell.svg';
import filterIcon from '../../assets/badge.svg';
import noreports from '../../assets/NoReports.svg'
import badge from '../../assets/Filter.svg';
import imgIcon from '../../assets/Image, Bild.svg'
import '../../style/reports.css';
import { extractDate, extractDateSimplified, extractTime } from "../../helpers/Helpers";
import { useDispatch, useSelector } from 'react-redux'
import IReportsModel from "../../features/models/IReportsModel";
import { fetchReportsRequest, resetEventsInStore } from "../../store/actions/search-reports.actions";
import { RootState } from "../../rootReducer";
import InfiniteScroll from "react-infinite-scroll-component";
import IEventsModel from "../../features/models/IEventsModel";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { fetchGetSitesRequest } from "../../store/actions/get-sites.actions";
import IAllSitesModel from "../../features/models/IAllSitesModel";
import close from '../../assets/CloseFilled.svg';
import { generatePath, useParams } from "react-router";
import arrow from '../../assets/Arrow.svg';
import note from '../../assets/Text, Notes.svg';
import { clearFilter, setLocationIds, setReportType, setSkipBeatsWithoutNotesOrImages, setSkipSize } from "../../store/slices/filter.slice";
import $, { event } from "jquery";
import { AppState } from "../../appState";
import { ISearchReportsRequestModel } from "../../features/models/ISearchReportsRequestModel";
import { clearFilterForm, setLocationIdsForm, setReportTypeForm, setSkipBeatsWithoutNotesOrImagesForm } from "../../store/slices/filterForm.slice";
import mixpanel from 'mixpanel-browser';




const Reports = () => {

    const dispatch = useDispatch();

    const [page, setPageSize] = useState(20)
    const [data, setData] = useState(Array<IEventsModel>)
    const [numberOfItems, setNumberOfItems] = useState(1)
    const [ref, inView] = useInView()
    const [filterOpened, setFilterOpened] = useState(true)
    const [hasMoreData, setHasMoreData] = useState(true)
    const [loader, setLoader] = useState(<div className='bottom-loader'><h3>Laddar...</h3></div>)
    const [noReports, setNoReports] = useState(false)



    const navigate = useNavigate();

    let content:any;

    const container = {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.5
        }
      }
    };


    const { loading, reportsModel, error } =  useSelector(   
      (state: RootState) => state.reportsState 
 );
  
    const { skipSize, pageSize, skipBeatsWithoutNotesOrImages, reportType, locationIds} =  useSelector(   
      (state: RootState) => state.filterState
    );

    const { skipSizeForm, pageSizeForm, skipBeatsWithoutNotesOrImagesForm, reportTypeForm, locationIdsForm} =  useSelector(   
      (state: RootState) => state.filterFormState
    );

     const { sitesModel } =  useSelector(   
      (state: RootState) => state.sitesState 
 );

      
    useEffect(() => {
      if(skipSize===0){
        fetchReports(pageSize, 0, locationIds, reportType, skipBeatsWithoutNotesOrImages);
        dispatch(setSkipSize(20))
        fetchSites();
        mixpanel.track('Reports Opened');
      }
    }, [])

    //FETCH API

    const fetchSites = () => {
      dispatch(fetchGetSitesRequest()) 
    }

     const  fetchReports = (pageNumber:number, skip:number, locationIds:string[], reportTypes:string[], skipBeatsWithoutNotesOrImages:boolean) => {
      dispatch(fetchReportsRequest({ locationIds: locationIds, pageSize: pageNumber, skip: skip, reportTypes: reportTypes, skipBeatsWithoutNotesOrImages: skipBeatsWithoutNotesOrImages }))
    }


    const fetchMore = (skip:number) =>{
      setNumberOfItems(reportsModel.reportsTotalCount)
      var uploadedEvents = pageSize + skipSize;
      if((uploadedEvents < reportsModel.reportsTotalCount)){
        fetchReports(pageSize,skipSize, locationIds, reportType, skipBeatsWithoutNotesOrImages)        
      }
      else if((uploadedEvents-reportsModel.reportsTotalCount<20)&&(uploadedEvents>reportsModel.reportsTotalCount)){
        fetchReports(pageSize,skipSize, locationIds, reportType, skipBeatsWithoutNotesOrImages)
        setHasMoreData(false)
        setLoader(<h1></h1>)
      }else if(uploadedEvents>reportsModel.reportsTotalCount){
        setHasMoreData(false)
        setLoader(<h1></h1>)
      }
      else if(uploadedEvents = reportsModel.reportsTotalCount){
        fetchReports(pageSize,skipSize, locationIds, reportType, skipBeatsWithoutNotesOrImages)
        setHasMoreData(false)
        setLoader(<h1></h1>)
      }
      dispatch(setSkipSize(uploadedEvents))
    }


    //HELPERS

    let date = "";
    function compareDates(currentDate:string, reportDate:string){
        if(currentDate.slice(0,-15)!== reportDate.slice(0,-15)){
          date = reportDate;
          return<div><div className="section-date-divider"></div>
          <div className="date">{extractDate(reportDate.slice(0,-15))}</div></div>
        }
      }

    function checkIfImageExist(images:any){
      if(images){
        if(images.length>0){
          return(
                <img className="img-icon" src={imgIcon}></img>
          )
        }
      }
      }

     function checkIfCommentExist(freeTextFromGuard:any){
      if(freeTextFromGuard){
        if(freeTextFromGuard!==null){
          return(
            <img className="img-icon-comment" src={note}></img>
          )
        }
      }
     } 


    //FILTER FUNCTIONS

    const setFilterBeat = (e:any) => {
        // dispatch(setSkipBeatsWithoutNotesOrImages(e.target.value==="false" ? false : true));
        dispatch(setSkipBeatsWithoutNotesOrImagesForm(e.target.value==="false" ? false : true));
    }


    const setFilterReportType = (e:any) => {
      // dispatch(setReportType(e.target.value === "" ? [] : [e.target.value]))
      dispatch(setReportTypeForm(e.target.value === "" ? [] : [e.target.value]))
    };

    function setFilterLocations(locationId:string){
      var tempList = [...locationIdsForm]
      if(locationIdsForm.includes(locationId)){
        tempList.splice(tempList.indexOf(locationId), 1);

      }else{
        tempList.push(locationId)
      }
      // dispatch(setLocationIds(tempList))
      dispatch(setLocationIdsForm(tempList))

    }

    function setFilter(){
      dispatch(setLocationIds(locationIdsForm))
      dispatch(setReportType(reportTypeForm))
      dispatch(setSkipBeatsWithoutNotesOrImages(skipBeatsWithoutNotesOrImagesForm))
      dispatch(resetEventsInStore())
      fetchReports(pageSizeForm, 0, locationIdsForm, reportTypeForm, skipBeatsWithoutNotesOrImagesForm)
      dispatch(setSkipSize(20))
      closeFilter()
      closeFilterNoReports()
      setHasMoreData(true)
      mixpanel.track('Selected Filters',{
        'SkipBeatsWithoutImagesOrComment': skipBeatsWithoutNotesOrImagesForm,
        'ReportType': reportTypeForm,
        'LocationIds': locationIdsForm,
        'pressedClear': false
      })
    }

    function checkSelected(){
      locationIdsForm.forEach(reportId => {
        $("#" + reportId).prop('checked', true);
      });
      if(reportTypeForm.length>0){
        $("#" + reportTypeForm).prop('checked', true);

      }else{
        $("#radio-all").prop('checked', true);
      }
      if(skipBeatsWithoutNotesOrImagesForm===true){
        $("#radio-skipBeatsWithoutNotesOrImages").prop('checked', true);
        
      }else{
        $("#radio-allOther").prop('checked', true);
      }
    }

    function resetChecked(){
      sitesModel.map((sites: IAllSitesModel )=> {
        $("#" + sites.id).prop('checked', false);
      })

      $("#radio-all").prop('checked', true);
      
      $("#radio-skipBeatsWithoutNotesOrImages").prop('checked', true);
      
    }

    function showFilter(){
      checkSelected();
      var filterElement = document.getElementById("filter-container");
      var scrollContainer = document.getElementById("scroll-container");
      var buttonContainer = document.getElementById("buttons-container");
      if(filterOpened===true){
        if(filterElement&&scrollContainer&&buttonContainer){
          scrollContainer.classList.toggle('scroll-container-not-active')
          filterElement.classList.toggle('filter-container-move')
          buttonContainer.classList.toggle('buttons-container-show')
          document.body.style.overflow = " hidden"
          setFilterOpened(false)
        }  
      }else if(filterOpened===false){
        if(filterElement&&scrollContainer&&buttonContainer){
          scrollContainer.className= 'scroll-container'
          filterElement.className = 'filter-container'
          buttonContainer.className = 'buttons-container'
          document.body.style.overflow = " scroll"
          setFilterOpened(true)
        }
      }
    }

    function closeFilter(){
      var filterElement = document.getElementById("filter-container");
      var scrollContainer = document.getElementById("scroll-container");
      var buttonContainer = document.getElementById("buttons-container");
      if(filterElement&&scrollContainer&&buttonContainer){
        scrollContainer.className= 'scroll-container'
        filterElement.className = 'filter-container'
        buttonContainer.className = 'buttons-container'
        document.body.style.overflow = " scroll"
        setFilterOpened(true)
      }  
    }


    function showFilterNoReports(){
      checkSelected();
      var filterElement = document.getElementById("filter-container");
      var buttonContainer = document.getElementById("buttons-container");

      if(filterOpened===true){
        if(filterElement&&buttonContainer){
          filterElement.classList.toggle('filter-container-move')
          buttonContainer.classList.toggle('buttons-container-show')
          setFilterOpened(false)
        }  
      }else if(filterOpened===false){
        if(filterElement&&buttonContainer){
          filterElement.className = 'filter-container'
          buttonContainer.className = 'buttons-container'
          setFilterOpened(true)
        }
      }
    }

    function closeFilterNoReports(){
      var filterElement = document.getElementById("filter-container");
      var buttonContainer = document.getElementById("buttons-container");
      if(filterElement&&buttonContainer){
        filterElement.className = 'filter-container'
        buttonContainer.className = 'buttons-container'
        setFilterOpened(true)
      }  
    }

    function resetFilter(){
      dispatch(clearFilterForm())
      resetChecked()
      mixpanel.track('Selected Filters',{
        'SkipBeatsWithoutImagesOrComment': true,
        'ReportType': [],
        'LocationIds': [],
        'pressedClear': true
      })


    }

    function setAnalytics(event:IEventsModel){

        mixpanel.track('Report Details Opened', {
          'Callout': event.reportType === "CalloutReport" ? true : false,
          'Beat': event.reportType === "BeatReport" ? true : false,
          'HasImages': event.images === null ? false : true,
          'HasComment': event.freeTextFromGuard === "" ? false : true
        })
      
    }

    //END FILTER FUNCTIONS


    function checkIfAlert(reportType:any, event:IEventsModel){
        //CALLOUT
        if(reportType==="CalloutReport"){
          return <li key={event.eventId}>
          <Link to={`/reports/`+ event.eventId} className="report-element" onClick={()=>setAnalytics(event)}>
            <div className='type-container'>
              <div className="report-name"><h3>{event.locationName}</h3></div>
              <div className="alarm-type-callout">
                <img className="bell-img" src={bell}></img>
                <div className="alarm-text"><h4>{event.alarmTypeText}</h4></div>
                <div className="report-time"><div id="time">{extractDateSimplified(event.reportStartDateTime)} • {extractTime(event.reportStartDateTime)}</div>
                </div>
                </div>

                <div className="category-img-container"> 
                <div className="report-desc-container">            
              <p>{event.alarmIssueDescription}</p>
              </div>         
               <div className="icon-container">
               {checkIfImageExist(event.images)}
                {checkIfCommentExist(event.freeTextFromGuard)}
                </div>
                {/* <div className="category-container">
                  {event.eventCategories.map((category: string) => (
                  <p key={category}>{category}</p>
                  ))}  
              </div>   */}
            </div>
            </div>
          </Link>
        </li>
        }
        //BEATREPORT
        else{
          return <li key={event.eventId}>
          <Link to={`/reports/`+ event.eventId} className="report-element" onClick={()=>setAnalytics(event)}>
            <div className='type-container'>
              <div className="report-name"><h3>{event.locationName}</h3></div>
              <div className='alarm-type-beat'>
              <div className="alarm-text-beat"><h4>{event.prodObjDescription}</h4></div>
              <div className="report-time"><div id="time">{extractDateSimplified(event.reportStartDateTime)} • {extractTime(event.reportStartDateTime)}</div>
              </div>
              </div>
              <div className="category-img-container"> 
              <div className="report-desc-container">            
              <p>{event.specification}, {event.eventText}, {event.action}</p>
              </div>   
              <div className="icon-container">  
              {checkIfImageExist(event.images)}
              {checkIfCommentExist(event.freeTextFromGuard)}     
              </div>
              {/* <div className="category-container">
              {event.eventCategories.map((category: string) => (
              <p key={category}>{category}</p>
              ))}  
              </div>   */}
            </div>
            </div>
          </Link>
        </li>
        }
      }

    if (loading && reportsModel.events.length === 0) {
      content =             
      <div >
      <div className="header-page">
      <h1>Rapporter</h1>
      </div>
      <div className="loader-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
      <Navbar></Navbar>

  </div>
    }      
    if (reportsModel.events.length > 0 && reportsModel.reportsTotalCount !== 0  ){ 
        content = (
            <div className="wrapper">
            <div className="header-page-filter">
            <div><h1>Rapporter</h1></div>
            <button className="trigger" onClick={()=>showFilter()}>            
            <div className="header-filter"><h6>Filter</h6></div>
          <img className="badge" src={badge}></img><img className="filterIcon" id="filterIcon" src={filterIcon}></img></button>
            </div>
            <div id="scroll-container">
            <InfiniteScroll
                  dataLength={reportsModel.events.length}
                  next={() => fetchMore(skipSize)}
                  hasMore={hasMoreData}
                  loader={loader}
                  >
              <motion.ul initial="hidden" animate="show" variants={container} ref={ref}>
                {
                reportsModel.events.map((event:IEventsModel) => 
                  <>    
                  <div className="section-date" key={"id" + event.eventId}><h5>{compareDates(date, event.reportStartDateTime)}</h5></div>
                  {checkIfAlert(event.reportType, event)}    
                  </>                      
                )}
              </motion.ul>
            </InfiniteScroll>
            </div>
            <Navbar></Navbar>
            <div>
        <div className="filter-container close" id="filter-container">
          <img className="close-img" src={close} onClick={() => closeFilter()}></img>
        <div>
            <div className="filter-header" id="rapporter">
            <h1>Rondrapport - Innehåll</h1>
            <h2>Obs. gäller endast för rondrapporter. För åtgärdsrapporter visas allt innehåll.</h2>
            <div className="radio-container">
              <fieldset>
            <div>
            <label htmlFor="radio-skipBeatsWithoutNotesOrImages" className="reports-name"><p>Endast med bild och-/eller kommentar</p>
                <input type="radio" id="radio-skipBeatsWithoutNotesOrImages" name="filter-rond" value={'true'} onClick={setFilterBeat} defaultChecked/>
                <span className="checkmark" ></span>
                    </label>
            </div>
            <div>
            <label htmlFor="radio-allOther" className="reports-name"><p>Visa allt</p>
                <input type="radio" id="radio-allOther" name="filter-rond" value={'false'} onClick={setFilterBeat}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            </fieldset>
            </div>
            </div>
            <div className="filter-header" id="reports">
            <h1>Rapporttyp</h1>
            <div className="radio-container">
              <fieldset>
            <div>
            <label htmlFor="radio-all" className="reports-name"><p>Alla</p>
                <input type="radio" id="radio-all" name="filter-all" value={""} onClick={setFilterReportType} defaultChecked/>
                <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="BeatReport" className="reports-name"><p>Rond</p>
                <input type="radio" id="BeatReport" name="filter-all" value={"BeatReport"} onClick={setFilterReportType}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="CalloutReport" className="reports-name"><p>Åtgärd / Utryckning</p>
                <input type="radio" id="CalloutReport" name="filter-all" value={"CalloutReport"} onClick={setFilterReportType}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            </fieldset>

            </div>
            </div>
            <div className="filter-header" id="sites">
            <h1>Platser</h1>
            {                   
                sitesModel.map((proper:IAllSitesModel) => 
                <div className="checkbox-container" key={proper.id}> 
                    <label htmlFor={proper.id} className="sites-name"><p>{proper.properties.name?.map(n => n.value)}</p>
                    <input type="checkbox" id={proper.id} name="filter-reports" value={proper.id} onClick={() => setFilterLocations(proper.id)}/>
                    <span className="checkmark"></span>
                    </label>
                </div>
                )
            }
            <div className="buttons-container" id="buttons-container">
                <button id="clear" type="reset" onClick={() => resetFilter()}>Rensa</button>
                <button id="filter" type="submit" onClick={() => setFilter()}>Filter</button>   
            </div>
            </div>
            </div>

        </div>
    </div>  
        </div>
        )
    } else if (reportsModel.reportsTotalCount===0&&!loading) {
      content = (
        < >
            <div className="header-page-filter">
            <div><h1>Rapporter</h1></div>
            <button className="trigger" onClick={()=>showFilterNoReports()}>            
            <div className="header-filter"><h6>Filter</h6></div>
          <img className="badge" src={badge}></img><img className="filterIcon" id="filterIcon" src={filterIcon}></img></button>
            </div>
        <nav id="menu-chat" className="menu-chat">
            <div className="chat-container">
            <img className="noreports-img" src={noreports}></img>
            <p id="construction-text">Det finns inga rapporter för valda platser och rapporttyper. Prova en annan filterkonfiguration.</p>
            </div>
          </nav>
        <Navbar></Navbar>        
        <div>
        <div className="filter-container close" id="filter-container">
          <img className="close-img" src={close} onClick={() => closeFilterNoReports()}></img>
        <div>
            <div className="filter-header" id="rapporter">
            <h1>Rondrapport - Innehåll</h1>
            <h2>Obs. gäller endast för rondrapporter. För åtgärdsrapporter visas allt innehåll.</h2>
            <div className="radio-container">
              <fieldset>
            <div>
            <label htmlFor="radio-skipBeatsWithoutNotesOrImages" className="reports-name"><p>Endast med bild och-/eller kommentar</p>
                <input type="radio" id="radio-skipBeatsWithoutNotesOrImages" name="filter-rond" value={'true'} onChange={setFilterBeat} defaultChecked/>
                <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="radio-allOther" className="reports-name"><p>Visa allt</p>
                <input type="radio" id="radio-allOther" name="filter-rond" value={'false'} onChange={setFilterBeat}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            </fieldset>
            </div>
            </div>
            <div className="filter-header" id="reports">
            <h1>Rapporttyp</h1>
            <div className="radio-container">
              <fieldset>
            <div>
            <label htmlFor="radio-all" className="reports-name"><p>Alla</p>
                <input type="radio" id="radio-all" name="filter-all" value={""} onChange={setFilterReportType} defaultChecked/>
                <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="BeatReport" className="reports-name"><p>Rond</p>
                <input type="radio" id="BeatReport" name="filter-all" value={"BeatReport"} onChange={setFilterReportType}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="CalloutReport" className="reports-name"><p>Åtgärd / Utryckning</p>
                <input type="radio" id="CalloutReport" name="filter-all" value={"CalloutReport"} onChange={setFilterReportType}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            </fieldset>

            </div>
            </div>
            <div className="filter-header" id="sites">
            <h1>Platser</h1>
            {                   
                sitesModel.map((proper:IAllSitesModel) => 
                <div className="checkbox-container" key={proper.id}> 
                    <label htmlFor={proper.id} className="sites-name"><p>{proper.properties.name?.map(n => n.value)}</p>
                    <input type="checkbox" id={proper.id} name="filter-reports" value={proper.id} onClick={() => setFilterLocations(proper.id)}/>
                    <span className="checkmark"></span>
                    </label>
                </div>
                )
            }
            <div className="buttons-container" id="buttons-container">
                <button id="clear" type="reset">Rensa</button>
                <button id="filter" type="submit" onClick={() => setFilter()}>Filter</button>   
            </div>
            </div>
            </div>

        </div>
    </div>  
        </>
    );
    } else if(error){
    
      content = (
        <div >
        <div className="header-page">
        <h1>Rapporter</h1>
        </div>
        <div className="loader-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        <Navbar></Navbar>
        
    </div>);
    }

    return content
}
export default Reports