import React from 'react';
import './App.css';
import Login from './components/Login';
import { Router, Routes, Route } from "react-router-dom";
import Chat from './components/chat/Chat';
import Menu from './components/Menu';
import Reports from './components/reports/Reports'
import Layout from './components/Layout';
import RequireAuth from './features/auth/RequireAuth';
import ReportDetails from './components/reports/ReportsDetails';
import Filter from './components/Filter'
import { HashRouter } from 'react-router-dom'




function App() {
  return (
    <main className='App'>
        <Routes>
        <Route path="/" element={<Layout />} > 
        {/* Public Routes */}
          <Route index element={<Login />}/> 
          {/* Protected Routes */}
          <Route path="/reports" element={<RequireAuth><Reports /></RequireAuth>}/>  
          <Route path="/reports/:id" element={<RequireAuth><ReportDetails /></RequireAuth>}/>    
          <Route path="/chat" element={<RequireAuth><Chat /></RequireAuth>}/>  
          <Route path="/menu" element ={<RequireAuth><Menu /></RequireAuth>}/>  
          <Route path="/filter" element={<Filter/>}/>
          <Route path="*" element={<RequireAuth><Reports /></RequireAuth>} />
          
        </Route>
      </Routes>
      </main>
  );
}

export default App;
