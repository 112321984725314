import { IGetMessagesResponse } from "../../features/models/IGetMessagesResponse";
import axios, { AxiosRequestConfig } from 'axios';
import IReportsModel from "../../features/models/IReportsModel";
import { FetchReportsSearchRequest } from "../types/search-reports.types";
import { SagaIterator } from 'redux-saga';
import { put, call, all, takeEvery } from 'redux-saga/effects';
import { fetchReportsFailure, fetchReportsSuccess, fetchReportsAddItemSuccess } from "../actions/search-reports.actions";
import { getMessagesReqestType } from "../actions/action-types";
import instance from "../../features/auth/RefreshToken";
import IAllSitesModel from "../../features/models/IAllSitesModel";
import { FetchGetSitesRequest } from "../types/get-sites.types";
import { fetchGetMessagesFailure, fetchGetMessagesSuccess } from "../actions/get-messages.actions";

const API_URL = "messages";

const requestHeaders: AxiosRequestConfig = {

    headers: {

      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
  }
  }

const getMessagesRequest = () => instance.get<IGetMessagesResponse>(

    API_URL, requestHeaders);

export  function* getMessagesActionSaga():SagaIterator {

    try {
        
        const response =  yield call(getMessagesRequest);

        yield put(

             fetchGetMessagesSuccess({

                messages: response.data

            })      

        )

    } catch (e: any) {

        yield put(

            fetchGetMessagesFailure({

                error: e.message

            })

        );

    }

}

function* searchMessagesSaga() {

    yield all([takeEvery(getMessagesReqestType.GET_MESSAGES_REQUEST, getMessagesActionSaga)]);

}
export default searchMessagesSaga;