import React,{useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../rootReducer";
import "../style/filter.css";
import {fetchGetSitesRequest} from "./../store/actions/get-sites.actions"
import Navbar from "./Navbar";
import IAllSitesModel from "../features/models/IAllSitesModel";

export default function Filter(){



    const dispatch = useDispatch();
    let content:any;
    const [sites, setSites]= useState<IAllSitesModel[]>();
    const [locationIds, setLocationIds] = useState([]);

    useEffect(() => {
        fetch()
    },[])

    const { loading, sitesModel, error } =  useSelector(   
        (state: RootState) => state.sitesState 
   );

    const fetch = () => {
        dispatch(fetchGetSitesRequest()) 
      }

    function getLocations(){
        debugger

    }

    if(loading){
        content =             
        <div >
        <div className="header-page">
        <h1>Rapporter</h1>
        </div>
        <div className="loader-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
        <Navbar></Navbar>
  
    </div>
    } 
    
    if(!loading&&sitesModel.length>1){
        content =
        <div>
        <div className = "filter-container">
        <form>
            <div className="filter-header">
            <h1>Rapporter</h1>
            <div className="radio-container">
            <div>
            <label htmlFor="radio-all" className="reports-name">Alla
                <input type="radio" id="radio-all" name="filter-reports" value={[]}/>
                <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="radio-beat" className="reports-name">Rond
                <input type="radio" id="radio-beat" name="filter-reports" value={"beatreport"}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            <div>
            <label htmlFor="radio-callout" className="reports-name">Utryckning
                <input type="radio" id="radio-callout" name="filter-reports" value={"callout"}/>
                    <span className="checkmark"></span>
                    </label>
            </div>
            </div>
            </div>
            <div className="filter-header">
            <h1>Platser</h1>
            {                   
                sitesModel.map((proper:IAllSitesModel) => 
                <div className="checkbox-container">
                    
                    <label htmlFor={proper.id} className="sites-name">{proper.properties.name.map(n => n.value)}
                    <input type="checkbox" id={proper.id} name="filter-reports" value={proper.id}/>
                    <span className="checkmark"></span>

                    </label>
                </div>
                )
            }
            <div className="buttons-container">
                <button type="reset">Rensa</button>
                <button type="submit" onClick={() => getLocations()}>Filter</button>   
            </div>
            </div>
            </form>

        </div>
    </div>  

    }
    return content

}