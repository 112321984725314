/* eslint-disable import/no-anonymous-default-export */

import { IGetMessagesResponse } from "../../features/models/IGetMessagesResponse";
import { IMessagesState } from "../../features/models/IMessagesState";
import { getMessagesReqestType } from "../actions/action-types";
import { ActionGetMessagesActions } from "../types/get-messages.types";


const initialState: IMessagesState = {

    messages: [],
    loading: false,
    error: null
    

}

export default (state = initialState, action: ActionGetMessagesActions) => {

    switch (action.type) {

        case getMessagesReqestType.GET_MESSAGES_REQUEST:


            return {

                ...state,

                loading: true

            };

        case getMessagesReqestType.GET_MESSAGES_SUCCESS:

            return {

                ...state,

                messages: action.payload.messages,

                loading: false,

                error: null

            };

        case getMessagesReqestType.GET_MESSAGES_FAILURE:

            return {

                ...state,

                loading: false,

                error: action.payload.error

            };

        default:

            return {

                ...state

            };
    }

};
