import searchReportsSaga from "./store/sagas/search-reports.saga";
import getSitesSaga from "./store/sagas/get-sites.saga"
import getMessagesSaga from "./store/sagas/get-messages.saga"
import { all, fork } from "redux-saga/effects";

export function* rootSaga() {

    yield all([fork(searchReportsSaga)]);
    yield all([fork(getSitesSaga)]);
    yield all([fork(getMessagesSaga)]);


}