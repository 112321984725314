import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface IFilterFormOptions {
    pageSizeForm: number,
    skipSizeForm: number,
    skipBeatsWithoutNotesOrImagesForm: boolean,
    reportTypeForm: string[],
    locationIdsForm:string[]
  }

  const initialState: IFilterFormOptions = {
    pageSizeForm: 20,
    skipSizeForm: 0,
    skipBeatsWithoutNotesOrImagesForm: true,
    reportTypeForm: [],
    locationIdsForm:[]
    
  }

  const filterFormSlice = createSlice({
    name: 'filterFormOptions',
    initialState,
    reducers:{
        setPageSizeForm(state, action: PayloadAction<number>){
            state.pageSizeForm = action.payload
        },
        setSkipSizeForm(state, action: PayloadAction<number>){
            state.skipSizeForm = action.payload
        },
        setSkipBeatsWithoutNotesOrImagesForm(state, action: PayloadAction<boolean>){
            state.skipBeatsWithoutNotesOrImagesForm = action.payload
        },
        setReportTypeForm(state, action: PayloadAction<string[]>){
            state.reportTypeForm = [...action.payload]
        },
        setLocationIdsForm(state, action: PayloadAction<string[]>){
            state.locationIdsForm = [...action.payload]
        },
        clearFilterForm(state){
            state.skipSizeForm = 0;
            state.skipBeatsWithoutNotesOrImagesForm = true;
            state.reportTypeForm = [];
            state.locationIdsForm = [];

        }
    }
  })

  export const { setPageSizeForm, setSkipSizeForm, setSkipBeatsWithoutNotesOrImagesForm, setReportTypeForm, setLocationIdsForm, clearFilterForm } = filterFormSlice.actions
export default filterFormSlice.reducer