import axios from "axios";
import IUserModel from '../../features/models/IUserModel';
import { logout } from "./Auth";
import { Link, useNavigate } from "react-router-dom";


export const appBaseUrl = "https://gip-backend.azurewebsites.net/";

export function getLocalAccessToken() {
    const user = localStorage.getItem("user") || "";
    if(user){
        const token = JSON.parse(user) as IUserModel
        return token.jwtToken;
    }
  }
  
  export function getLocalRefreshToken() {
    const user = localStorage.getItem("user") || "";
    if(user){
        const token = JSON.parse(user) as IUserModel
        return token.refreshToken;
    }
  }
  let loading=0;
  function refreshToken() {
    const response = instance.post("/users/refresh-token", 
    getLocalRefreshToken()
 )
 loading++;
  return response;

  }

const instance = axios.create({
    baseURL: "https://gip-backend.azurewebsites.net/",
     headers: {
      "Content-Type": "application/json",
    },
  });
  
  instance.interceptors.request.use(
    
    (config) => {
      const token = getLocalAccessToken();
      if (token&&config.headers) {
        config.headers.Authorization = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;  
      if (err.response) {
        if (err.response.status === 401 && !originalConfig._retry && loading<2) {
          originalConfig._retry = true;
          try {
            const rs = await refreshToken();
            const { accessToken } = rs.data;
            localStorage.setItem("user", JSON.stringify(rs.data));
            instance.defaults.headers.common.Authorization = 'Bearer ' + accessToken;
            loading=0;

          } catch (_error:any) {

            if (_error.response && _error.response.data) {   

              return Promise.reject(_error.response.data);
            }
            return Promise.reject(_error);
          }
          return instance(originalConfig);

        }

        else if (err.response.status === 403 && err.response.data) {
          return Promise.reject(err.response.data);
        }

        else if(err.response.status === 401 && !originalConfig._retry && loading >=2){
          localStorage.clear();
          document.location.reload();

        }
      }

      return Promise.reject(err);
    }
  );
export default instance;
  