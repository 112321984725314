/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React,{useState, useEffect} from "react";
import { generatePath, useParams } from "react-router";
import arrow from '../../assets/Arrow.svg';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import bell from '../../assets/RedBell.svg';
import '../../style/reportDetails.css';
import { extractDate, extractTime } from "../../helpers/Helpers"
import Navbar from "../Navbar";
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from "../../rootReducer";
import IReportsModel from "../../features/models/IReportsModel";
import IEventsModel from "../../features/models/IEventsModel";
import IUserModel from "../../features/models/IUserModel";
import { getImages } from "../../features/auth/Auth";
import axios from "axios";
import { any } from "prop-types";
import { appBaseUrl } from "../../features/auth/RefreshToken";
import { report } from "process";
import e from "express";



const ReportDetails = () => {

    const id = useParams().id;
    console.log(id)

    const [singleItem, setSingleItem] = useState<IEventsModel>();
    const [imagesList, setImages] = useState(Array<string>);
    const [imagesDowloaded, setImagesDowloaded] = useState(false);


    const navigate = useNavigate();

    const { loading, reportsModel, error } = useSelector(

        (state: RootState) => state.reportsState
  
    );
    
     if(reportsModel&&!singleItem){
        setSingleItem(reportsModel.events.find(ev => ev.eventId === id));
     }   

     useEffect(() => {
        getAllImages()
      }, [])

     const getAllImages =  async () => {
        if(singleItem && singleItem.images){
            for (let index = 0; index < singleItem.images.length; index++) {
                 await axios.get(appBaseUrl+"reports/imageAttached/"+ singleItem.images[index])
                .then(res => {
                  const img = res.data.fileContents;
                    imagesList.push(img)       
                })
            }
            setImagesDowloaded(true);
        }else{
            setImagesDowloaded(true);

        }
    }
    
    function showImages(){
        return(
        imagesList.map(image =>
            <div>
                <img className="scroll-img" src={"data:image/png;base64," + `${image}`} alt="image" />  
                </div>
            )
            )       
    }

    function displayImagesLabel(image:any){
        console.log(image.length)
        if(image!==null && image.length!==0){return(<h4>Bilder</h4>)}
        else{return(<h4></h4>)}
    }

    function addCategories(category:[]){
        if(category.length !== 0 ){   
            return(
                category.map(item =>
            <div className="category"><p>{item}</p></div>
             ))
        }
        else{
            return(
                null
            )
        }
    }

    function addCategoryIfExist(eventCategoryList:[]){
        if(eventCategoryList.length>0){
            return(
            <div className="categories-container">
            <h4>Kategorier</h4>
            {addCategories(eventCategoryList)}
            </div>)
        }
        else {
            return null;
        }  
    }

    let content:any;

    if(imagesDowloaded===false) {
    content =   
    <div >
        <div className="header-page">
            <h1>Rapporter</h1>
        </div>
        <div className="loader-container"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    </div>
    }
    else if(singleItem?.reportType==="BeatReport" && imagesDowloaded===true) {
        console.log(imagesDowloaded)           
        content =   
        <div>              
            <div>
                <div className="header-page">
                    <h1>
                        <img className="arrow-img" src={arrow} onClick={() => navigate(-1)}></img>Rond
                    </h1>
                </div>
            <div className="main-container">
                <div>
                    <h2>{singleItem.prodObjDescription}</h2>
                        <div className="date-time-container">
                            <div className="element-time">{extractTime(singleItem.reportStartDateTime)}</div>
                            <div className="element-dot">•</div>
                            <div className="element-date">{extractDate(singleItem.reportStartDateTime).slice(0, -5)}</div>
                        </div>
                    <div className="event-details-container">
                        <div className="client-header"><h4>Kund</h4>
                            <div className="detail-section">
                                <div className="object-type"><p>Plats</p>
                            </div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc">
                                <p>{singleItem.locationName}</p>
                            </div>
                            </div>
                            <div className="detail-section">
                            <div><p>Objektnamn</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc">
                                <p>{singleItem.prodObjDescription}</p>
                            </div> 
                        </div> 

                        <div className="detail-section">
                            <div><p>Address</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-address">
                                <p>{singleItem.locationAddress}, {singleItem.locationCity}</p>
                            </div> 
                        </div>                       
                        </div>
                        <div className="details-header"><h4>Rapportdetaljer</h4>
                            <div className="detail-section">
                                <div className="object-type"><p>Avvikelse</p></div>
                                <div className="object-underline"></div>
                                <div className="event-object-desc">
                                    <p>{singleItem.specification}, {singleItem.eventText}, {singleItem.action}</p>
                                </div>
                            </div>
                            {singleItem.locationObjectDescription &&(
                            <div className="detail-section">
                            <div className="object-type"><p>Streckkodsposition</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc">
                                <p>{singleItem.locationObjectDescription}</p>
                            </div>
                            </div> 
                            )}
                    
                        </div>
                    </div>
                    {/* {addCategoryIfExist(singleItem.eventCategories)} */}
                    <div className="report-details-comment"><h4>{(singleItem.freeTextFromGuard.length)>0 ? "Kommentar": ""}</h4>
                    <div className="comment">{singleItem.freeTextFromGuard}</div>
                    </div>
                    <div className="report-details-images">{displayImagesLabel(singleItem.images)}
                    <div className="images-section">
                    {showImages()}
                    </div>
                    </div>
                 </div>      
            </div>
        </div>
    </div>    
   
    }
    else if(singleItem?.reportType==="CalloutReport" && imagesDowloaded===true){
        content =   
        <div>   
        <div>
            <div className="header-page">
                <h1><img className="arrow-img" src={arrow} onClick={() => navigate(-1)}></img>Utryckning</h1>
            </div>
            <div className="main-container">
                <div>
                    <div className="callout-header">
                        <img className="bell-callout-img" src={bell}></img>
                        <h2>{singleItem.alarmTypeText}</h2>
                    </div>
                    <div className="date-time-container">
                        <div className="element-time">{extractTime(singleItem.reportStartDateTime)}</div>
                        <div className="element-dot">•</div>
                        <div className="element-date">{extractDate(singleItem.reportStartDateTime).slice(0, -5)}</div>
                    </div>
                    <div className="event-details-container">
                        <div className="client-header"><h4>Kund</h4>
                        <div className="detail-section">
                            <div className="object-type"><p>Plats</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc"><p>{singleItem.locationName}</p></div>
                        </div>
                        <div className="detail-section">
                            <div><p>Objektnamn</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc"><p>{singleItem.objectName}</p></div> 
                        </div>  
                        <div className="detail-section">
                            <div><p>Address</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-address"><p>{singleItem.locationAddress}, {singleItem.locationCity}</p></div> 
                        </div>                       
                        </div>
                        <div className="details-header"><h4>Alarm</h4>
                        <div className="detail-section">
                            <div className="object-type"><p>Rapportval</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc"><p>{singleItem.alarmIssueDescription}</p></div>
                            </div>
                            {singleItem.alarmCenter && (
                        <div className="detail-section">
                            <div className="object-type"><p>Larmcentral</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-desc"><p>{singleItem.alarmCenter}</p></div>
                         </div>
                            )}

                        <div className="detail-section">
                            <div><p>Larm mottaget</p></div>
                            <div className="object-underline"></div>
                            <div className="event-object-address"><p>{extractTime(singleItem.reportStartDateTime)}</p></div> 
                        </div>                       
                        </div>
                        <div className="details-header"><h4>Vakt(er) på plats</h4>
                        <div className="detail-section">
                            {singleItem.guardType === "H" &&(
                            <div className="object-type"><p>Huvudväktare</p></div>
                            )}
                            {singleItem.guardType === "A" &&(
                            <div className="object-type"><p>Assisterande</p></div>
                            )}
                            {singleItem.guardType !== "A" && singleItem.guardType !== "H" &&(
                            <div className="object-type"><p>Vakt</p></div>
                            )}
                            <div className="object-underline"></div>
                            <div className="event-object-desc"><p>{extractTime(singleItem.arrivalDateTime)}-{extractTime(singleItem.departureDateTime)}</p></div>
                            </div>                    
                        </div>
                    </div>
                    <div className="event-details-container">
                      
                    </div>
                    {/* {addCategoryIfExist(singleItem.eventCategories)} */}
                    <div className="report-details-comment"><h4>{singleItem.freeTextFromGuard!==null ? "Kommentar": ""}</h4>
                    <div className="comment">{singleItem.freeTextFromGuard}</div>
                    </div>
                    <div className="report-details-images"><h4>{singleItem.images!==null ? "Bilder" : ""}</h4>
                    <div className="images-section">
                     {showImages()}
                     </div>
                     </div>
                </div>   
            </div>
        </div>
    </div>
    }   
    return content 
    }


export default ReportDetails;