
import { combineReducers } from "redux";
import searchReportsReducer from "./store/reducers/search-reports.reducer";
import getSitesReducer from "./store/reducers/get-sites.reducer";
import getFilterReducer from "./store/slices/filter.slice";
import getMessagesReducer from "./store/reducers/get-messages.reducer"
import getFilterFormReducer from "./store/slices/filterForm.slice";


const rootReducer = combineReducers({
    reportsState: searchReportsReducer,
    sitesState: getSitesReducer,
    filterState: getFilterReducer,
    messagesState: getMessagesReducer,
    filterFormState: getFilterFormReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;