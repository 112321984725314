/* eslint-disable import/no-anonymous-default-export */
import  IReportsState  from "../../features/models/I-reports.state";
import  IEventsModel  from "../../features/models/IEventsModel";
import IReportsModel from "../../features/models/IReportsModel";
import { clearTypes, searchReportsReqestTypes } from "../actions/action-types";
import { ActionReportsSearchActions } from "../types/search-reports.types";

const initialState: IReportsState = {

    reportsModel:{
        events:[],
        reportsTotalCount:0
    } as IReportsModel,
    loading:false,
    error:null
}



export default (state = initialState, action: ActionReportsSearchActions) => {


    switch (action.type) {

        case searchReportsReqestTypes.SEARCH_REPORTS_REQUEST:


            return {

                ...state,

                loading: true

            };

        case searchReportsReqestTypes.SEARCH_REPORTS_SUCCESS:

            return {

                ...state,

                // reportsModel: action.payload.reports,

                reportsModel: {
                    events: [...state.reportsModel.events, ...action.payload.reports.events],
                    reportsTotalCount: action.payload.reports.reportsTotalCount
                },

                loading: false,

                error: null

            };

        case searchReportsReqestTypes.SEARCH_REPORTS_FAILURE:

            return {

                ...state,

                loading: false,

                error: null

            };

        case clearTypes.SEARCH_REPORTS_CLEAR_LIST:
        return {
            ...state,

            reportsModel:{
                events:[],
                reportsTotalCount:0
            }
        };

        default:

            return {

                ...state

            };

    }

};
