export enum searchReportsReqestTypes {

    SEARCH_REPORTS_REQUEST = "SEARCH_REPORTS_REQUEST",
    SEARCH_REPORTS_SUCCESS = "SEARCH_REPORTS_SUCCESS",
    SEARCH_REPORTS_FAILURE = "SEARCH_REPORTS_FAILURE",
    SEARCH_REPORTS_ADD_ELEMENT = "SEARCH_REPORTS_ADD_ELEMENT",

}

export enum clearTypes {

    SEARCH_REPORTS_CLEAR_LIST = "SEARCH_REPORTS_CLEAR_LIST"

}

export enum getSitesReqestTypes {

    GET_SITES_REQUEST = "GET_SITES_REQUEST",
    GET_SITES_SUCCESS = "GET_SITES_SUCCESS",
    GET_SITES_FAILURE = "GET_SITES_FAILURE",

}

export enum logoutUser {

    LOGOUT_USER = "LOGOUT_USER"
}

export enum getMessagesReqestType{

    GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST",
    GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS",
    GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE"
}

export enum postMessageReqestType{

    POST_MESSAGES_REQUEST = "POST_MESSAGES_REQUEST",
    POST_MESSAGES_SUCCESS = "POST_MESSAGES_SUCCESS",
    POST_MESSAGES_FAILURE = "POST_MESSAGES_FAILURE"
}