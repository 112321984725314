/* eslint-disable import/no-anonymous-default-export */
import ISitesState from "../../features/models/I-sites.state";
import IAllSitesModel from "../../features/models/IAllSitesModel";
import { getSitesReqestTypes } from "../actions/action-types";
import { ActionGetSitesActions } from "../types/get-sites.types";

const initialState: ISitesState = {

    sitesModel:[{
        id:"",
        label:"",
        properties:{},
        propertiesFlattened:false,
        outEdges:[]
    }] as IAllSitesModel[],
    loading:false,
    error:null,

}

export default (state = initialState, action: ActionGetSitesActions) => {


    switch (action.type) {

        case getSitesReqestTypes.GET_SITES_REQUEST:


            return {

                ...state,

                loading: true

            };

        case getSitesReqestTypes.GET_SITES_SUCCESS:

            return {

                ...state,

                sitesModel: action.payload.sites,

                loading: false,

                error: null

            };

        case getSitesReqestTypes.GET_SITES_FAILURE:

            return {

                ...state,

                loading: false,

                error: action.payload.error

            };

        default:

            return {

                ...state

            };
    }

};
