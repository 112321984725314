/* eslint-disable jsx-a11y/alt-text */
import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import securitasLogo from '../assets/Securitas Logo - With White Text.svg'
import { login } from '../features/auth/Auth'
import '../style/login.css';
import mixpanel from 'mixpanel-browser';
import { mixPanelToken } from '..';


const Login = () => {
    const [username, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const navigate = useNavigate()


    useEffect(()=>{
      mixpanel.track('Login Opened')
    },[])

    const handleSubmit = async (e:any) => {
        e.preventDefault()
        mixpanel.track('Login request', {
          'Login request': true,
          'username': username
        });

        try {
            const response = await login(username, password)
            localStorage.setItem("user", JSON.stringify(response.data));
            console.log(response.data)
            setUser('')
            setPassword('')
            navigate('/reports')
            mixpanel.identify(response.data.username);
            mixpanel.people.set({ 
              "$name": response.data.username,
              "guardDistrict": response.data.guardDistrict,
              "isInternalUser": response.data.isInternalUser
           });
      
        } catch (err) {
            console.log(err)
            const wrongredentialsMessage = document.getElementById("wrong-credentials")
            if(wrongredentialsMessage){
              wrongredentialsMessage.style.display = "block";
            }

        }
    }

    const handleUserInput = (e:any) => setUser(e.target.value);
    const handlePwdInput = (e:any) => setPassword(e.target.value);
    

    // const content = isLoading ? <h1>Loading...</h1> : (
        const content = (
        <section className="login">
            <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <div className="login-wrapper">
      <h1>Guard Interaction Platform</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <input type="text" placeholder="Användare"  id="username"
                    value={username}
                    onChange={handleUserInput}
                    autoComplete="off"
                    required />
        </label>
        <label>
          <input type="password" placeholder="Lösenord" id="password"
                    onChange={handlePwdInput}
                    value={password}
                    required />
        </label>
        <div>
          <button type="submit">Logga in</button>
        </div>
      </form>
      <div>
        <div className='wrong-credentials' id="wrong-credentials"><p>Felaktigt inlogg eller lösenord</p></div>
        </div>
      <div className="footer-wrapper">
      <img className="img-sec" src={securitasLogo}></img>
        <p>Copyright © 2022 Securitas Intelligent Services AB. Guard Interaction Platform (GIP)</p>
      </div>
    </div>
        </section>
    )

    return content
}
export default Login