import { useLocation, Navigate, Outlet, Link, useOutlet  } from "react-router-dom";
import { useSelector } from "react-redux";
import IUserModel from "../models/IUserModel";
import React from "react";

const RequireAuth = ({children}:any) => {
    const location = useLocation()
    const userSessionStorage =  localStorage.getItem("user") || "" ;

    if(!userSessionStorage){
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children
}
export default RequireAuth