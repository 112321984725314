/* eslint-disable jsx-a11y/alt-text */
import menu from '../assets/Menu, Meny.svg';
import reports from '../assets/Reports, Rapporter.svg';
import chat from '../assets/Chat.svg';
import { NavLink } from 'react-router-dom';
import '../style/navbar.css';
import { ReactNode } from 'react';
import mixpanel from 'mixpanel-browser';



export default function Navbar(){

    interface Props {
        children?: ReactNode,
        href:any,
        img:any,
    }

    function setAnalytics(url:string){
        var pageSelected = url.slice(1).charAt(0).toUpperCase() + url.slice(2);
        mixpanel.track( pageSelected + ' Opened')
    }

    return <nav className="navbar">
        <ul>
            <CustomLink href="/reports" img={reports}>Rapporter</CustomLink>
            <CustomLink href="/chat" img={chat}>Chatt</CustomLink>
            <CustomLink href="/menu" img={menu}>Meny</CustomLink>
        </ul>
    </nav>
    
    function CustomLink({href, children, img, ...props}:Props){
        const path= window.location.hash
    
        return(
        <li className={path===("#"+href) ? "active" : ""} onClick={()=>setAnalytics(href)}>    
            <NavLink to={href} {...props}><img className="img" src={img}></img>{children}</NavLink>
        </li>
        )
    }   
}