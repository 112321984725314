import { ISearchReportsRequestModel } from "../../features/models/ISearchReportsRequestModel";
import axios, { AxiosRequestConfig } from 'axios';
import IReportsModel from "../../features/models/IReportsModel";
import { FetchReportsSearchRequest } from "../types/search-reports.types";
import { SagaIterator } from 'redux-saga';
import { put, call, all, takeEvery } from 'redux-saga/effects';
import { fetchReportsFailure, fetchReportsSuccess, fetchReportsAddItemSuccess } from "../actions/search-reports.actions";
import { searchReportsReqestTypes } from "../actions/action-types";
import instance from "../../features/auth/RefreshToken";

const API_URL = "reports/searchReports";

const requestHeaders: AxiosRequestConfig = {

    headers: {

      'Content-Type': 'application/json; charset=utf-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
  }
  }

const searchReportsRequest = (requestedBody: ISearchReportsRequestModel) => instance.post<IReportsModel>(

    API_URL, requestedBody, requestHeaders);

export  function* searchReportsActionSaga( {payload}: FetchReportsSearchRequest):  SagaIterator {

    try {

        const requestModel: ISearchReportsRequestModel= {
            locationIds: payload.locationIds,
            pageSize: payload.pageSize,
            skip: payload.skip,
            reportTypes: payload.reportTypes,
            skipBeatsWithoutNotesOrImages: payload.skipBeatsWithoutNotesOrImages
        }
        
        const response =  yield call(searchReportsRequest, requestModel);

        yield put(

            fetchReportsSuccess({

                reports: response.data

            })      
        )
        
        yield put(
            fetchReportsAddItemSuccess({
                events: response.data.events
            })

        )

    } catch (e: any) {

        yield put(

            fetchReportsFailure({

                error: e.message

            })

        );

    }

}

function* searchReportsSaga() {

    yield all([takeEvery(searchReportsReqestTypes.SEARCH_REPORTS_REQUEST, searchReportsActionSaga)]);

}
export default searchReportsSaga;